import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {ApiService} from '@src/app/services/api.service';
import {ScrollViewService} from '@src/app/services/scroll-view.service';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Dossier, DOSSIER_STATUS} from '@src/app/model/dossier.model';
import {Activity, AffairNavItems, ItemUpload} from '@src/app/shared/interfaces';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {DownloadService} from '@src/app/services/download.service';
import {filter, takeUntil} from 'rxjs/operators';
import {DossierService} from '@src/app/services/dossier.service';
import {DocumentService} from '@src/app/services/document.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-affair-dossier',
    templateUrl: './affair-dossier.component.html',
    styleUrls: ['./affair-dossier.component.scss'],
    providers: [ActionBottomSheetService],
})
export class AffairDossierComponent extends BaseComponent implements OnInit {
    title = 'Chargement...';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    private id: number;
    dossier: Dossier;
    parentDossier: {id: number, label: string};
    isBusy: boolean;
    upload: ItemUpload = {
        isUploading: false,
        isProcessing: false,
        progress: 0,
    };
    activityOpen = false;
    spinner = {
        allDownload: false,
        allValid: false,
        diffuse: false,
        valideCa: false,
        refuseCa: false,
        valideResponse: false,
        message: false,
    };
    selectedIdDocumentHistorique: number;
    selectedDocumentHistorique: DocumentHistorique;
    previewIdDocumentHistorique: number;
    activityList: Activity[];
    statusLabel = DOSSIER_STATUS;
    activeStatus = 1;
    initiale: string;
    message: string;
    back: any[];
    backQueryParams: any;
    querySearch: any;
    items: AffairNavItems = {
        back: true,
        state: true,
        views: false,
        collaboratif: true,
        equipe: true,
    };

    constructor(
        private scrollViewService: ScrollViewService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private uploadImageService: UploadImageService,
        private documentService: DocumentService,
        private downloadService: DownloadService,
        public viewContainerRef: ViewContainerRef,
        private dossierService: DossierService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateBackdrop(this.backdrop);
        this.sharedService.updateMode(this.mode);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.initiale = this.storageService.initiale;
        this.selectedDocumentHistorique = null;
        this.activatedRoute.queryParams.pipe(takeUntil(this.notifier)).subscribe(params => {
            this.selectedIdDocumentHistorique = params['_d'];
        });
        this.activatedRoute.params.pipe(takeUntil(this.notifier)).subscribe(params => {
            this.id = params['id'];
            this.previewIdDocumentHistorique = params['preview'];
        });
        this.getDossier();

        this.router.events
            .pipe(
                takeUntil(this.notifier),
                filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationStart|NavigationEnd) => {
                if (event instanceof NavigationStart) { this.isBusy = true; }
                if (event instanceof NavigationEnd) {
                    const url = event.url.split('/');
                    if (url[1] === 'affair-dossier') { this.getDossier(); }
                }
            });
    }

    // TODO if this.id not util outside of getDossier delete it
    getDossier() {
        this.dossierService.get(this.id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier) => {
                this.dossier = dossier;
                this.parentDossier = this.dossier.parentDossiers.find(d => d.id === this.dossier.parentId);
                this.title = this.dossier.affair.label + ' - ' + this.dossier.label;
                this.sharedService.updateTitle(this.title);
                this.isBusy = false;
                this.upload.isUploading = false;
                this.upload.isProcessing = false;
                this.upload.progress = 0;
                this.dossier.documentHistoriques.map(dh => {
                    if (parseInt(dh.id + '', 10) === parseInt(this.selectedIdDocumentHistorique + '', 10)) {
                        this.documentClickedHandler({documentHistorique: dh, isOpen: true});
                    }
                });

                if (this.dossier.parentId) {
                    this.back = ['/affair-dossier', this.dossier.parentId];
                    this.title = this.parentDossier.label;
                    this.backQueryParams = {};
                } else {
                    this.back = ['/affaire', this.dossier.affair.id];
                    this.title = this.dossier.affair.label;
                    this.backQueryParams = {'_s': this.dossier.step.id};
                }

                this.querySearch = {
                    affair: this.dossier.affair.id,
                    step: this.dossier.step.id,
                    dirid: this.dossier.id,
                    dirName: this.dossier.label,
                };
            });
    }

    // for App
    goToStatut(status: number) {
        this.activeStatus = status;
    }

    // For web
    handleFileInput(files) {
        this.upload.progress = 0;
        this.upload.isUploading = true;
        this.upload.isProcessing = false;

        this.dossierService.upload(this.dossier, files)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.upload.isProcessing = true;
                        break;
                    case HttpEventType.ResponseHeader:
                        // TODO voir si utile
                        break;
                    case HttpEventType.UploadProgress:
                        this.upload.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        if (files.length > 1) {
                            this.getDossier();
                        } else {
                            this.router.navigate(
                                [
                                    'affair-dossier',
                                    this.id,
                                    'ajouter',
                                    event.body.id,
                                    event.body.document.name
                                ],
                                {skipLocationChange: true}
                            );
                        }
                        break;
                }
            }, () => {
                this.upload.progress = 0;
                this.upload.isUploading = false;
                this.upload.isProcessing = false;
            });
    }

    // both but commented on app
    onTapDownloadAllDocument() {
        this.spinner.allDownload = true;
        this.dossierService.download(this.dossier).then(() => {
            this.spinner.allDownload = false;
        }, () => this.spinner.allDownload = false);
    }

    // TODO activity service
    documentClickedHandler(value: { documentHistorique: DocumentHistorique, isOpen: boolean }) {
        if (value.isOpen) {
            this.activityOpen = true;
            this.apiService.getActivityList(value.documentHistorique.document.id).subscribe((activities) => {
                value.documentHistorique.nbNotifications.total = 0;
                value.documentHistorique.nbDiscutions = 0;
                this.activityList = activities;
            });
            this.refreshInterval = setInterval(() => {
                this.apiService.getActivityList(value.documentHistorique.document.id).subscribe((activities) => {
                    value.documentHistorique.nbNotifications.total = 0;
                    value.documentHistorique.nbDiscutions = 0;
                    this.activityList = activities;
                });
            }, 120000);
            this.selectedDocumentHistorique = value.documentHistorique;
        } else {
            this.activityOpen = false;
            this.selectedDocumentHistorique = null;
            clearInterval(this.refreshInterval);
        }
    }

    // Activity
    // for web
    // TODO activity service
    onSubmitMessage() {
        if (this.message) {
            this.spinner.message = true;
            this.apiService.postActivityMessage(
                this.selectedDocumentHistorique.document.id,
                {message: this.message}
            ).subscribe((result) => {
                this.message = '';
                this.activityList = result;
                this.spinner.message = false;
            });
        }
    }

    // for web
    onTapCloseActivity() {
        this.activityOpen = false;
        clearInterval(this.refreshInterval);
    }

    // TODO activity service
    activityAddedHandler(documentHistorique: DocumentHistorique) {
        // TODO check maj list document quand changement état
        this.dossier.documentHistoriques.map(dh => dh.id === documentHistorique.id ? documentHistorique : dh);
        if (this.selectedDocumentHistorique && this.selectedDocumentHistorique.id === documentHistorique.id) {
            this.apiService.getActivityList(documentHistorique.document.id).subscribe((activities) => {
                this.activityList = activities;
            });
        }
    }

    // Dossier

    dossierDeletedHandler(dossier: Dossier): void {
        this.dossier.dossiers = this.dossier.dossiers.filter(d => d.id !== dossier.id);
    }

    onTapValidAll(dossier: Dossier): void {
        this.spinner.allValid = true;
        this.dossierService.validAll(dossier).subscribe(() => {
            this.dossier.documentHistoriques.map(dh => dh.state = 3);
            this.spinner.allValid = false;
            this.activeStatus = 3;
        });
    }

    onTapAuthorizeDossier(dossier: Dossier): void {
        this.dossierService.authorize(dossier, null, false, false, this.viewContainerRef).subscribe((result: Dossier) => {
            if (result) {
                this.dossier.dossiers = this.dossier.dossiers.map(d => d.id === result.id ? result : d);
            }
        });
    }

    // TODO On peux utilisé onTapAuthorizeDossierModal partout, mais refaire le cheminement de modal coté appli
    // onTapAuthorizeDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {id: dossier.id, type: 'dossier-societies'},
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     ).pipe(takeUntil(this.notifier)).subscribe((result) => {
    //         if (result) {
    //             this.dossier.dossiers = this.dossier.dossiers.map(d => d.id === result.id ? result : d);
    //         }
    //     });
    // }

    onTapAddDossier(dossier: Dossier) {
        this.dossierService.add(dossier, false, this.viewContainerRef).subscribe((result: Dossier) => {
                if (result) { this.dossier.dossiers.push(result); }
            });
    }

    // TODO On peux utilisé onTapAddDossierModal partout, mais refaire le cheminement de modal coté appli
    // onTapAddDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {
    //             type: 'dossier-add',
    //             state: dossier.state,
    //             affair: dossier.affair.id,
    //             step: dossier.step.id,
    //             parentDossiers: dossier.id,
    //         },
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     ).pipe(takeUntil(this.notifier)).subscribe((result) => {
    //         if (result) { this.dossier.dossiers.push(result); }
    //     });
    // }

    // onTapDoDossier(dossier: Dossier) {
    //     this.dossierService.state(dossier, 1)
    //         .subscribe((result: ActionStateResult) => {
    //             if (result) { this.dossier.state = 1; }
    //         });
    // }
    //
    // onTapWorkDossier(dossier: Dossier) {
    //     this.dossierService.state(dossier, 2)
    //         .subscribe((result: ActionStateResult) => {
    //             if (result) { this.dossier.state = 2; }
    //         });
    // }
    //
    // onTapValidDossier(dossier: Dossier) {
    //     this.dossierService.state(dossier, 3)
    //         .subscribe((result: ActionStateResult) => {
    //             if (result) { this.dossier.state = 3; }
    //         });
    // }
    //
    // onTapMore(dossier: Dossier, type: string) {
    //     this.dossierService.more(dossier, type)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe(result => {
    //             if (result && result.action) {
    //                 setTimeout(() => {
    //                     // TODO refactor nom de fonction retourné pour appel direct au lieu du switch
    //                     // this[result.action](dossier);
    //                     switch (result.action) {
    //                         case 'onTapDo':
    //                             this.onTapDoDossier(dossier);
    //                             break;
    //                         case 'onTapWork':
    //                             this.onTapWorkDossier(dossier);
    //                             break;
    //                         case 'onTapValid':
    //                             this.onTapValidDossier(dossier);
    //                             break;
    //                     }
    //                 }, 300);
    //             }
    //         });
    // }

    private uploadFile(dossier, event) {
        console.log('uploadFile', event);
        this.documentService.waitingFile(dossier, event, 'upload', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                if (result) {
                    if (result.multiple) {
                        this.getDossier();
                    } else {
                        setTimeout(() => {
                            this.router.navigate(
                                ['affair-dossier', dossier.id, 'ajouter', result.response.id, result.response.document.name],
                                {skipLocationChange: true}
                            );
                        }, 300);
                    }
                }
            });
    }

    private onTapAddFile(dossier: Dossier) {
        this.uploadImageService.uploadFile('upload').then((event) => {
            if (event.object.results.length) {
                setTimeout(() => {
                    this.uploadFile(dossier, event);
                }, 300);
            }
        });
    }

    private onTapAddMedia(dossier: Dossier) {
        this.uploadImageService.uploadMedia('upload').then((event) => {
            if (event.object.results.length) {
                setTimeout(() => {
                    this.uploadFile(dossier, event);
                }, 300);
            }
        });
    }

    private onTapAddCamera(dossier: Dossier) {
        this.uploadImageService.uploadCamera('upload').then((event) => {
            if (event.object.results.length) {
                setTimeout(() => {
                    this.uploadFile(dossier, event);
                }, 300);
            }
        });
    }

    public onTapAdd(dossier: Dossier) {
        this.documentService.moreFile(this.dossier, 'upload', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        console.log('onTapAdd', result);
                        this[result.action](dossier);
                    }, 300);
                }
            });
    }
}
