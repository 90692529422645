import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Dossier, DOSSIER_TYPES} from '@src/app/model/dossier.model';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {MatRadioChange} from '@angular/material/radio';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {User} from '@src/app/model/user.model';
import {StorageService} from '@src/app/services/storage.service';
import {Affair} from '@src/app/model/affair.model';

@Component({
    selector: 'app-modal-dossier',
    templateUrl: 'modal-dossier.component.web.html',
})
export class ModalDossierComponent implements OnInit {

    notifier = new Subject();
    isLoading: boolean;
    isSaving: boolean;
    dossier: Dossier;
    parentDossier: Dossier;
    dossierForm: FormGroup;
    types = DOSSIER_TYPES;

    constructor(
        public dialogRef: MatDialogRef<ModalDossierComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {dossier?: Dossier, parentDossier?: Dossier, isSpecificList?: boolean, affair?: Affair},
        private apiService: ApiService,
        private toastService: ToastService,
        private cdRef: ChangeDetectorRef,
        private storageService: StorageService,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isSaving = false;
        if (this.data.dossier && this.data.dossier.id) {
            this.apiService.getDossierLight(this.data.dossier.id).subscribe((dossier: Dossier) => {
                this.dossier = dossier;
                this.createForm();
                this.isLoading = false;
            });
        } else if (this.data.parentDossier) {
            this.parentDossier = this.data.parentDossier;
            if (this.data.parentDossier.id) {
                this.apiService.getDossierLight(this.data.parentDossier.id).subscribe((dossier: Dossier) => {
                    this.dossier = new Dossier();
                    this.dossier.state = dossier.state;
                    this.dossier.typeSpecific = dossier.code === 'CHIFFRAGE_1' ? 1 : '';
                    this.dossier.affair = {id: dossier.affair.id};
                    this.dossier.step = {id: dossier.step.id};
                    this.dossier.parentDossiers = [{id: dossier.id, label: dossier.label}];
                    this.createForm();
                    this.isLoading = false;
                });
            } else {
                this.dossier = this.parentDossier;
                this.dossier.typeSpecific = '';
                this.createForm();
                this.isLoading = false;
            }
        } else {
            this.dossier = this.data.dossier;
            this.createForm();
            this.isLoading = false;
        }
    }

    createForm(): void {
        this.dossierForm = new FormGroup({
            label: new FormControl(this.dossier.label),
            type: new FormControl(this.dossier.typeSpecific),
            state: new FormControl(this.dossier.state),
            dateLimit: new FormControl(this.dossier.dateLimit),
        });
        this.cdRef.detectChanges();
    }

    onChangeType(event: MatRadioChange): void {
        this.cdRef.detectChanges();
        this.dossierForm.updateValueAndValidity();
    }

    onSubmit(): void {
        this.isSaving = true;
        if (this.dossierForm.valid) {
            if (this.dossier.id) {
                const data = this.dossierForm.value;
                data.dateLimit = this.formatDate(data.dateLimit);
                data.specificList = !!this.data.isSpecificList;
                data.users = this.dossier.users.map((user: User) => user.id);
                data.shortcuts = this.dossier.shortcuts.map((shortcut: Dossier) => shortcut.id);
                this.apiService.postDossier(this.dossier.id, data).subscribe((dossier) => {
                    this.isSaving = false;
                    if (dossier) {
                        // this.toastService.show(`Le dossier "${dossier.label}" a bien été modifié.`);
                        this.dialogRef.close(dossier);
                    }
                }, () => this.isSaving = false);
            } else {
                const data = this.dossierForm.value;
                data.dateLimit = this.formatDate(data.dateLimit);
                data.specificList = !!this.data.isSpecificList;
                let parent = null;
                if (this.dossier.parentDossiers) {
                    parent = this.dossier.parentDossiers[0].id;
                }
                if (this.data.parentDossier && !this.data.parentDossier.isChargeAffair) {
                    data.users = [this.storageService.userId];
                }
                this.apiService.addDossier(this.dossier.affair.id, this.dossier.step.id, data, parent).subscribe((dossier) => {
                    this.isSaving = false;
                    if (dossier) {
                        // this.toastService.show(`Le dossier "${dossier.label}" a bien été créé.`);
                        this.dialogRef.close(dossier);
                    }
                }, () => this.isSaving = false);
            }
        }
    }

    private formatDate(date: any) {
        if (date) {
            if (typeof date === 'object') {
                return date.format('DD/MM/YYYY');
            } else {
                return moment(date).format('DD/MM/YYYY');
            }
        }
        return null;
    }
}
