<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">{{title}}</span>
    <button (click)="onClose()" mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isBusy">
        <form [formGroup]="userForm">
            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Civilité</mat-label>
                        <mat-select formControlName="civility">
                            <mat-option value="" disabled>Choisir une civilité</mat-option>
                            <mat-option *ngFor="let civility of civilities"
                                        [value]="civility.key">{{civility.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Prénom</mat-label>
                        <input matInput type="text" formControlName="name" required>
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Nom de famille</mat-label>
                        <input matInput type="text" formControlName="surname" required>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" formControlName="email" required>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Téléphone</mat-label>
                        <input matInput type="text" formControlName="phone">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Position</mat-label>
                        <input matInput type="text" formControlName="position">
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Adresse</mat-label>
                        <input matInput type="text" formControlName="address">
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Code postal</mat-label>
                        <input matInput type="text" formControlName="postalCode">
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Ville</mat-label>
                        <input matInput type="text" formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Pays</mat-label>
                        <input matInput type="text" formControlName="country">
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Note</mat-label>
                        <input matInput type="text" formControlName="libre">
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col">
                            <mat-form-field appearance="outline" class="input-full-width">
                                <mat-label>Entreprise</mat-label>
                                <mat-select formControlName="society" required>
                                    <mat-option value="" disabled>Choisir une entreprise</mat-option>
                                    <mat-option *ngFor="let society of societies" [value]="society.id">{{society.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-auto mt-1">
                            <button mat-stroked-button color="primary" type="button" (click)="onAddSociety()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Corps d'état</mat-label>
                        <mat-select multiple formControlName="trades" required>
                            <mat-option value="" disabled>Choisir des corps d'état</mat-option>
                            <mat-option *ngFor="let trade of trades" [value]="trade.id">{{trade.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" [formGroup]="userForm.get(['user'])">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Roles</mat-label>
                        <mat-select multiple formControlName="roles" required>
                            <mat-option value="" disabled>Choisir des roles</mat-option>
                            <mat-option *ngFor="let role of roles" [value]="role.key">{{role.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Affaires</mat-label>
                        <mat-select multiple formControlName="affairs">
                            <mat-option value="" disabled>Choisir des affaires</mat-option>
                            <mat-option *ngFor="let affair of affairs" [value]="affair.id">{{affair.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!isBusy" mat-dialog-actions class="justify-content-end pb-3">
    <button [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Valider
    </button>
</div>
