import {Component, Inject, OnInit, OnDestroy, ViewContainerRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Trade} from '@src/app/model/trade.model';
import {ROLES, User} from '@src/app/model/user.model';
import {ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {CIVILITES} from '@src/app/model/contact.model';
import {Affair} from '@src/app/model/affair.model';
import {Society} from '@src/app/model/society.model';
import {ErrorStateMatcher} from '@src/app/shared/utils';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Location} from '@angular/common';
import {FormService} from '@src/app/services/form.service';
import {ModalService} from '@src/app/services/modal.service';

@Component({
    selector: 'app-user-modal-view-content',
    templateUrl: 'user-modal-view-content.component.web.html',
})
export class UserModalViewContentComponent implements OnInit, OnDestroy {

    title = 'Ajouter un utilisateur';
    notifier = new Subject();
    isBusy: boolean;
    isSaving = false;
    user: User;
    userForm: FormGroup;
    matcher: ShowOnDirtyErrorStateMatcher;
    civilities = CIVILITES;
    roles = ROLES;
    trades: Trade[];
    affairs: Affair[];
    societies: Society[];

    constructor(
        public dialogRef: MatDialogRef<UserModalViewContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {user: User},
        private apiService: ApiService,
        private location: Location,
        private toastService: ToastService,
        private formService: FormService,
        private modalService: ModalService,
        public viewContainerRef: ViewContainerRef,
    ) {
        if (this.data.user?.id) { this.title = 'Modifier un utilisateur'; }
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.apiService.getTrades()
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => this.trades = result.trades);
        this.apiService.getSocieties(1, 1000)
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => this.societies = result.societies);
        this.apiService.getAffairs()
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => this.affairs = result.affairs);
        if (this.data.user?.id) {
            this.apiService.getUser(this.data.user.id)
                .pipe(takeUntil(this.notifier))
                .subscribe((user) => {
                    this.user = user;
                    this.user.affairs = [];
                    this.user.affairIds.forEach((id) => {
                        this.user.affairs.push(id);
                    });
                    this.createForm();
                });
        } else {
            this.user = new User();
            this.createForm();
        }
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onClose(args: User = null) {
        this.formService.user = args;
        this.dialogRef.close(args);
    }

    createForm() {
        this.isBusy = false;
        this.user.roles = this.formatArray(this.user.roles);
        this.user.affairs = this.formatArray(this.user.affairs);
        this.user.contact.trades = this.formatArray(this.user.contact.trades);
        this.userForm = new FormGroup({
            civility: new FormControl(this.user.contact.civility),
            name: new FormControl(this.user.contact.name),
            surname: new FormControl(this.user.contact.surname),
            email: new FormControl(this.user.contact.email),
            phone: new FormControl(this.user.contact.phone),
            position: new FormControl(this.user.contact.position),
            trades: new FormControl(this.user.contact.trades),
            address: new FormControl(this.user.contact.address),
            postalCode: new FormControl(this.user.contact.postalCode),
            city: new FormControl(this.user.contact.city),
            country: new FormControl(this.user.contact.country),
            hasUser: new FormControl(1),
            society: new FormControl(this.user.contact.societyId),
            libre: new FormControl(this.user.contact.libre),
        });
        if (this.user.id) {
            this.userForm.addControl('user', new FormGroup({
                roles: new FormControl(this.user.roles),
                affairs: new FormControl(this.user.affairs),
            }));
        } else {
            this.userForm.addControl('user', new FormGroup({
                // plainPassword: new FormGroup({
                //     first: new FormControl(),
                //     second: new FormControl(),
                // }, {validators: this.checkPasswords}),
                roles: new FormControl(this.user.roles),
                affairs: new FormControl(this.user.affairs),
            }));
        }

        this.matcher = new ErrorStateMatcher();
    }

    // checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    //     const first = group.get('first').value;
    //     const second = group.get('second').value;
    //
    //     return first === second ? null : {notSame: true};
    // }

    onAddSociety() {
        this.modalService.show(
            {},
            'society',
            this.viewContainerRef,
            false
        ).subscribe((result) => {
            if (result) {
                this.societies.push(result);
                this.societies.sort(this.compareSociety);
                this.userForm.get('society').patchValue(result.id);
            }
        });
    }

    compareSociety(a: Society, b: Society) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
            return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
            return 1;
        }
        return 0;
    }

    onSubmit(): void {
        if (this.userForm.valid) {
            this.isSaving = true;
            const data = this.userForm.value;
            data.trades = this.formatArray(data.trades);
            if (this.user.id && this.user.contact.id) {
                data.user.affairs = this.formatArray(data.user.affairs);
                this.apiService.putContact(this.userForm.value.society, this.user.contact.id, this.userForm.value)
                    .pipe(takeUntil(this.notifier))
                    .subscribe((contact) => {
                    this.isSaving = false;
                    if (contact) {
                        this.toastService.show(`L'utilisateur "${contact.name}" a été modifié.`);
                        this.onClose(contact);
                    }
                }, () => this.isSaving = false);
            } else {
                data.user.affairs = this.formatArray(data.user.affairs);
                this.apiService.postContact(this.userForm.value, this.userForm.value.society)
                    .pipe(takeUntil(this.notifier))
                    .subscribe((user: User) => {
                    this.isSaving = false;
                    if (user) {
                        this.toastService.show(`L'utilisateur "${user.fullName}" a été ajouté.`);
                        this.onClose(user);
                    }
                }, () => this.isSaving = false);
            }
        }
    }

    private formatArray(array) {
        if (array) {
            return array.map(i => i.hasOwnProperty('id') ? i.id : i);
        }
        return null;
    }
}
