<div class="container">
    <h1>POLITIQUE DE CONFIDENTIALITÉ DE YFERE.</h1>
    <p><small class="text-muted">Dernière mise à jour : 16 juin 2021</small></p>

    <h2>Introduction</h2>

    <p>La politique de confidentialité de YFERE est faite pour vous aider à comprendre comment nous collectons et utilisons les informations, et vous aider à prendre des décisions lorsque vous utilisez l'application et le site Yfere. En utilisant ou en accédant à Yfere, vous acceptez les pratiques décrites dans cette politique de confidentialité.</p>

    <p>L’application YFERE est à usage privé. Seul les utilisateurs ayant un compte créer par la société YFERE peuvent se connecter à l’application.</p>


    <h2>Quelles informations sont collectées</h2>

    <p>Lorsque vous vous connectez sur l’application YFERE ou que vous vous inscrivez, l’application YFERE collecte des informations pouvant inclure votre nom, votre adresse postale, numéro de téléphone, adresse e-mail, votre entreprise,  votre métier.</p>

    <p>
        Pour le bon fonctionnement de l’application, YFERE utilise l’état du réseau, l’accès à internet, les services de premier plan.<br>
        L’application YFERE demande des accès à votre espace de stockage (lecture/écriture), à votre caméra afin que vous puissiez charger et télécharger des documents (audio, vidéo, photo, texte) sur l’application.
    </p>

    <h2>Utilisation des informations</h2>

    <p>
        Nous utilisons uniquement les informations pour exploiter l'application et le site Web YFERE.<br>
        Aucun usage commercial n’est effectué de vos données.
    </p>

    <p>Nous ne divulguons pas les renseignements personnels recueillis hormis dans le cas où une divulgation est requise par la loi.</p>

    <h2>API Google</h2>

    <p>Nous utilisons les API Google et cette politique de confidentialité intègre la politique de confidentialité de Google (actuellement <a href="http://www.google.com/privacy.html">http://www.google.com/privacy.html</a>) telle que modifiée par Google de temps à autre.</p>

    <h2>La vie privée des enfants</h2>

    <p>YFERE se conforme à la loi de 1998 sur la protection de la vie privée en ligne des enfants et à toutes les autres lois applicables. Par conséquent, nous limitons notre site Web aux personnes de dix-huit ans ou plus.</p>

    <p>VOUS DEVEZ AVOIR DIX-HUIT (18) ANS OU PLUS POUR ACCÉDER À CE SITE WEB. SI VOUS AVEZ MOINS DE DIX-HUIT ANS, VOUS N'ÊTES PAS AUTORISÉ À ACCÉDER À CE SITE WEB POUR QUELQUE RAISON QUE CE SOIT. EN RAISON DES RESTRICTIONS D'ÂGE POUR L'UTILISATION DE CE SITE WEB, AUCUNE INFORMATION OBTENUE PAR CE SITE WEB, N'ENTREPRENDRE LA LOI SUR LA CONFIDENTIALITÉ EN LIGNE DES ENFANTS (COPA) ET N'EST PAS SURVEILLÉE COMME LE FAIRE.</p>

    <h2>Sécurité</h2>

    <p>YFERE prend les précautions appropriées pour protéger les informations de nos utilisateurs. Cependant, YFERE ne fait aucune déclaration ou garantie quant à la suffisance de ces mesures de sécurité. YFERE ne sera pas responsable des dommages résultant d'un manquement au respect de la présente politique de confidentialité en raison d'une violation de la sécurité, d'un dysfonctionnement technique ou d'un problème similaire.</p>

    <h2>Changements de politique</h2>

    <p>YFERE peut choisir d'étendre ses capacités pour obtenir des informations sur les utilisateurs à l'avenir. YFERE se réserve le droit, à tout moment, de modifier, d'altérer ou de mettre à jour cet accord de politique de confidentialité sans préavis. Les modifications entreront en vigueur dès leur publication dans l'application ou sur le site Web. Votre utilisation continue de YFERE après la publication des modifications de la politique de confidentialité constitue une reconnaissance et une acceptation de notre politique de confidentialité et de ses modifications.</p>

    <h2>Nous contacter</h2>

    <p>Si vous avez des questions ou des commentaires sur la politique de confidentialité, veuillez nous envoyer un courriel à <a href="mailto:p.echasseriaud@metalyfer.fr">p.echasseriaud@metalyfer.fr</a>.</p>

</div>