import { Component } from '@angular/core';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent extends BaseComponent {

  title = 'Politique de confidentialité';
  mode: MatDrawerMode = 'side';
  backdrop = false;

  constructor() {
    super();
    this.sharedService.updateTitle(this.title);
    this.sharedService.updateMode(this.mode);
    this.sharedService.updateBackdrop(this.backdrop);
  }

}
