<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">{{data.dossier?.id ? 'Modifier un dossier' : (data.parentDossier?.code === 'CHIFFRAGE_1') ? 'Ajouter un nouveau dossier de consultation' : 'Ajouter un dossier'}}</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right ml-2">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <form [formGroup]="dossierForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput type="text" formControlName="label" required cdkFocusInitial>
                    </mat-form-field>
                </div>

<!--                <div class="col-md-12" *ngIf="!data.parentDossier?.isSpecificDossier && data.parentDossier?.code !== 'CHIFFRAGE_1' && data.parentDossier?.code !== 'HYPOTHESE_2' && (dossier.step.id == 1 || dossier.step.id == 2)">-->
<!--                    <label id="radio-group-label2">Type</label>-->
<!--                    <mat-radio-group (change)="onChangeType($event)" formControlName="type" aria-label="Type" aria-labelledby="radio-group-label2" class="radio-group">-->
<!--                        <mat-radio-button class="radio-button" value="">Aucun</mat-radio-button>-->
<!--                        <mat-radio-button *ngIf="dossier.step.id == 1" [checked]="dossierForm.get('type').value == 1" class="radio-button" value="1">Chiffrage</mat-radio-button>-->
<!--                        <mat-radio-button *ngIf="dossier.step.id == 2" [checked]="dossierForm.get('type').value == 2" class="radio-button" value="2">Hypothèse</mat-radio-button>-->
<!--                    </mat-radio-group>-->
<!--                </div>-->

                <div class="col-md-12" *ngIf="!data.dossier?.id && (dossierForm.get('type').value == 1)">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Date limite</mat-label>
                        <input matInput formControlName="dateLimit" [matDatepicker]="picker" (focusin)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button *ngIf="dossier.id" [disabled]="!dossierForm.valid || isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Modifier
    </button>
    <button *ngIf="!dossier.id" [disabled]="!dossierForm.valid || isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">create_new_folder</mat-icon>
        {{ (data.parentDossier?.code === 'CHIFFRAGE_1') ? 'Créer un dossier de consultation' : 'Ajouter' }}
    </button>
</div>
